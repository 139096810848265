import { setRemoteDefinitions } from '@nrwl/angular/mf';
import { environment } from './environments/environment';

console.log("in the main.ts file");

import('./bootstrap').then((value) =>{
    fetch(environment.federationAPI_URL)
        .then((res) => {
            console.log("loading remote definitions: ", res);
            return res.json();
        })
        .then((definitions) => {
            console.log("setting remote definitions: ", definitions);
            setRemoteDefinitions(definitions);
        })
        .then(() => {
            console.log("after calling bootstrapping", value);
            value.loadBootstrap();

            console.log("after calling bootstrapping");
        });

});

